import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-user/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Embark on your KOPE journey by registering yourself and your organization.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Accessing KOPE</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Registering an Organisation</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Logging In</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Landing Page Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Setting up your Organisation's Market Profile</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Permissions and Limitations</AnchorLink>
    </AnchorLinks>
    <h2>{`Accessing KOPE`}</h2>
    <p>{`You can access KOPE from the following web address: `}<a parentName="p" {...{
        "href": "https://construct.kope.ai"
      }}>{`construct.kope.ai`}</a></p>
    <h2>{`Registering an Organisation`}</h2>
    <p>{`To register an organisation:`}</p>
    <ol>
      <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`Register Organisation`}</inlineCode>{` Button.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/1_register-organisation-button.png",
          "alt": "1_register-organisation-button.png"
        }}></img></li>
      <li parentName="ol">{`The KOPE Register screen will open.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/2_register-organisation-form.png",
          "alt": "2_register-organisation-form.png"
        }}></img></li>
      <li parentName="ol">{`Complete the form by inputting the following details:`}
        <ol parentName="li">
          <li parentName="ol">{`Organisation Name`}</li>
          <li parentName="ol">{`Website Address`}</li>
          <li parentName="ol">{`Your Email Address`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Register`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`The Organisation Successfully Registered screen indicates creation success.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/3_organisation-successfully-registered.png",
          "alt": "3_organisation-successfully-registered.png"
        }}></img></li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Login and Edit`}</inlineCode>{` to go to your Organisation page.`}</li>
    </ol>
    <p>{`You will receive a confirmation email with an invitation link, you can use this link to log into your organisation. Alternatively, follow the Logging In steps below.`}</p>
    <h3>{`Adding Users to your Organisation`}</h3>
    <p>{`To add extra users to your organisation profile on KOPE, please contact KOPE and we will be happy to assist.`}</p>
    <h2>{`Logging In`}</h2>
    <p>{`To log into KOPE:`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/4_signup-login-button.png",
      "alt": "4_signup-login-button.png"
    }}></img>
    <ol>
      <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`Log in`}</inlineCode>{` button.`}</li>
      <li parentName="ol">{`Select a Login method:`}
        <ol parentName="li">
          <li parentName="ol">{`Type your email address and click the `}<inlineCode parentName="li">{`Enter`}</inlineCode>{` key.`}</li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Continue with Microsoft`}</inlineCode>{` Azure AD.
This will redirect you to your organisation’s Microsoft login process, so use your usual company details. This may or may not include multi-factor authentication.
`}<img parentName="li" {...{
              "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/5_logging-in-welcome-page.png",
              "alt": "5_logging-in-welcome-page.png"
            }}></img></li>
          <li parentName="ol">{`The Choose an Organisation screen will open.
`}<img parentName="li" {...{
              "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/6_logging-in-choose-and-organisation.png",
              "alt": "6_logging-in-choose-and-organisation.png"
            }}></img></li>
          <li parentName="ol">{`Select an Organisation from the drop-down list.`}</li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Login`}</inlineCode>{` to log into KOPE.`}</li>
        </ol>
      </li>
    </ol>
    <h2>{`Landing Page Overview`}</h2>
    <p>{`Once you have set up your organisation’s profile. The KOPE landing page will become available.`}</p>
    <p><strong parentName="p">{`Some of the following sections will remain locked until you have set up your organisation’s profile.`}</strong></p>
    <p>{`The KOPE landing page features multiple sections:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Market Profile`}</inlineCode>{` is a shortcut to your marketplace profile. Here you can view and edit your profile._`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Case Studies`}</inlineCode>{` showcases a selection of your case studies and provides a shortcut to add new case studies._`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Supply Chains`}</inlineCode>{` showcases a selection of your supply chains and provides a shortcut to add a new supply chain._`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Products (Supplier Profiles Only)`}</inlineCode>{` showcases a brief snapshot of your products and provides a shortcut to create new products._`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Projects`}</inlineCode>{` showcases your existing projects and provides a shortcut to create new projects._`}</li>
    </ul>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/7_kope-landing-page.png",
      "alt": "7_kope-landing-page.png"
    }}></img>
    <h2>{`Setting up your Organisation’s Market Profile`}</h2>
    <p>{`Upon logging into your profile for the first time, you will be prompted to specify whether you are a Supplier or a Specifier for your Market Profile.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/8_set-up-welcome-screen.png",
      "alt": "8_set-up-welcome-screen.png"
    }}></img>
    <h3>{`Supplier vs Specifier`}</h3>
    <p>{`The sections of KOPE visible to your organisation differ between Suppliers and Specifiers.`}</p>
    <p>{`The following table shows which sections are visible to Specifiers and Suppliers.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/9_supplier-vs-specifier.png",
      "alt": "9_supplier-vs-specifier.png"
    }}></img>
    <h3>{`Supplier Setup`}</h3>
    <p>{`If you are a supplier looking to set up your KOPE Marketplace profile:`}</p>
    <ol>
      <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`I'm a supplier`}</inlineCode>{` button.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/10_supplier-profile-im-a-supplier.png",
          "alt": "10_supplier-profile-im-a-supplier.png"
        }}></img></li>
      <li parentName="ol">{`The Supplier Profile register screen will open.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/11_supplier-profile-basic-information.png",
          "alt": "11_supplier-profile-basic-information.png"
        }}></img></li>
      <li parentName="ol">{`Enter the Basic Information for your organisation.`}
        <ol parentName="li">
          <li parentName="ol">{`Organisation Name`}</li>
          <li parentName="ol">{`(Optional) Former Name`}</li>
          <li parentName="ol">{`(Optional) Parent Organisation Name`}</li>
          <li parentName="ol">{`Year Founded`}</li>
          <li parentName="ol">{`(Optional) Company Number`}</li>
          <li parentName="ol">{`Organisation Size`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Next`}</inlineCode>{`. The Public Profile tab will open.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/12_supplier-profile-public-profile.png",
          "alt": "12_supplier-profile-public-profile.png"
        }}></img></li>
      <li parentName="ol">{`Enter the Public Profile Information for your organisation.`}
        <ol parentName="li">
          <li parentName="ol">{`Website Address`}</li>
          <li parentName="ol">{`(Optional) Public Email Address`}</li>
          <li parentName="ol">{`(Optional) Public Phone Number`}</li>
          <li parentName="ol">{`(Optional) Logo Image`}</li>
          <li parentName="ol">{`(Optional) Description of Business`}</li>
          <li parentName="ol">{`(Optional) Profiles`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Next`}</inlineCode>{`, and the Locations tab will open.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/13_supplier-profile-locations.png",
          "alt": "13_supplier-profile-locations.png"
        }}></img></li>
      <li parentName="ol">{`Enter the Location information for your organisation.`}
        <ol parentName="li">
          <li parentName="ol">{`Countries of Operation`}</li>
          <li parentName="ol">{`Headquarters Location`}</li>
          <li parentName="ol">{`Manufacturing Location(s)`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Next`}</inlineCode>{`, and the Sectors tab will open.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/14_supplier-profile-sectors.png",
          "alt": "14_supplier-profile-sectors.png"
        }}></img></li>
      <li parentName="ol">{`Enter the Sectors information for your organisation.`}
        <ol parentName="li">
          <li parentName="ol">{`Sectors of Operation � Select which sectors your organisation caters for.`}</li>
          <li parentName="ol">{`(Optional) Construction Value Chain.`}</li>
          <li parentName="ol">{`Organisation Questions.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Next`}</inlineCode>{`, the MMC Categories tab will open.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/15_supplier-profile-mmc-categories.png",
          "alt": "15_supplier-profile-mmc-categories.png"
        }}></img></li>
      <li parentName="ol">{`Enter the MMC Categories information for your organisation, and select which of the MMC Categories your products fall under.`}</li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Next`}</inlineCode>{`, and the Images tab will open.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/16_supplier-profile-images.png",
          "alt": "16_supplier-profile-images.png"
        }}></img></li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Select File(s)`}</inlineCode>{` to upload any images you want visible on your Market Profile.`}</li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Next`}</inlineCode>{`, the Certifications tab will open.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/17_supplier-profile-certifications.png",
          "alt": "17_supplier-profile-certifications.png"
        }}></img></li>
      <li parentName="ol">{`Enter any certifications or product guarantees your organisation holds from the list.`}</li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Next`}</inlineCode>{`, the FAQs tab will open.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/18_supplier-profile-faqs.png",
          "alt": "18_supplier-profile-faqs.png"
        }}></img></li>
      <li parentName="ol">{`Pick up to 6 question prompts to help potential buyers get to know your organisation in more detail.`}</li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Next`}</inlineCode>{`, andthe Confirmation tab will open.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/19_supplier-profile-confirmation.png",
          "alt": "19_supplier-profile-confirmation.png"
        }}></img></li>
      <li parentName="ol">{`Enter your Name and Email.`}</li>
      <li parentName="ol">{`Confirm that all information provided is accurate and complies with the Terms of Service using the toggle.`}</li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Register`}</inlineCode>{`.`}</li>
    </ol>
    <p><strong parentName="p">{`Congratulations`}</strong>{` , your organisation’s profile is now uploaded to the KOPE Marketplace.`}</p>
    <h3>{`Specifier Setup`}</h3>
    <h2>{`Permissions and Limitations`}</h2>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/20_admin-permissions-table.png",
      "alt": "20_admin-permissions-table.png"
    }}></img>
    <h3>{`Admins`}</h3>
    <p>{`The first user to log in or register to the organisation account is granted Admin rights. If you require additional admins, please get in contact with KOPE and we will be happy to assist.`}</p>
    <p>{`Admins can:`}</p>
    <ul>
      <li parentName="ul">{`Edit and delete items.`}</li>
      <li parentName="ul">{`Access and edit the Organisation settings.`}</li>
    </ul>
    <h3>{`Non-Admins`}</h3>
    <p>{`Non-Admins can:`}</p>
    <ul>
      <li parentName="ul">{`Edit and delete items created by themselves.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      